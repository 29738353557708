// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-first-floor-js": () => import("./../../../src/pages/first-floor.js" /* webpackChunkName: "component---src-pages-first-floor-js" */),
  "component---src-pages-ground-floor-js": () => import("./../../../src/pages/ground-floor.js" /* webpackChunkName: "component---src-pages-ground-floor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lower-ground-floor-js": () => import("./../../../src/pages/lower-ground-floor.js" /* webpackChunkName: "component---src-pages-lower-ground-floor-js" */),
  "component---src-pages-second-floor-js": () => import("./../../../src/pages/second-floor.js" /* webpackChunkName: "component---src-pages-second-floor-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

